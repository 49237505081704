import { ref } from 'vue';
import { residentAuthApi } from '@/api';

interface Option {
    key: string;
    value: string;
}

interface RoomOption {
    ID: string;
    RoomName: string;
    RoomNumber: string;
    UnitID: string;
    Floor: string;
}

interface BuildingOption {
    ID: string;
    UnitName: string;
}

interface UserOption {
    ID: string;
    Name: string;
}

const init = () => {
    const buildOptions = ref<Option[]>([]);
    const aptOptions = ref<Option[]>([]);
    const userOptions = ref<Option[]>([]);

    let allBuilding: BuildingOption[] = [];
    let allRoom: {[key in number]: RoomOption[]} = {};
    let allUser: {[key in number]: UserOption[]} = {};

    const setBuildingOption = () => {
        buildOptions.value = [];
        allBuilding.forEach((build) => {
            buildOptions.value.push({
                key: build.ID,
                value: build.UnitName
            });
        });
    };

    const trackBuildId = ref(0);
    const setRoomOption = (buildId: number|string) => {
        aptOptions.value = [];
        trackBuildId.value = Number(buildId);
        if (allRoom[trackBuildId.value]) {
            allRoom[trackBuildId.value].forEach((room) => {
                aptOptions.value.push({
                    key: room.ID,
                    value: room.Floor
                        ? `${room.RoomName} ${room.RoomNumber} (${WordList.Floor} ${room.Floor})` : `${room.RoomName} ${room.RoomNumber}`
                });
            });
        }
    };

    const trackRoomId = ref(0);
    const setUserOptions = (roomId: number|string) => {
        userOptions.value = [];
        trackRoomId.value = Number(roomId);
        if (allUser[trackRoomId.value]) {
            allUser[trackRoomId.value].forEach((user) => {
                userOptions.value.push({
                    key: user.ID,
                    value: user.Name
                });
            });
        }
    };

    residentAuthApi.getCommunityBuildRoomUser((data: {
        build: BuildingOption[] ;
        room: {[key in number]: RoomOption[]};
        user: {[key in number]: UserOption[]};
    }) => {
        allBuilding = data.build;
        allRoom = data.room;
        allUser = data.user;
        setBuildingOption();
        setRoomOption(trackBuildId.value);
        setUserOptions(trackRoomId.value);
    });

    return {
        buildOptions,
        aptOptions,
        userOptions,
        setRoomOption,
        setUserOptions
    };
};

export default init;