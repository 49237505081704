import { ref, Ref, reactive } from 'vue';
import { AddFormDataType } from '@/views/pm/old-community/resident-auth/all.type';
import { ListHeaderItem, RequestData } from '@/components/common/list';
import { checkCard, checkOldCommunityDeliveryPIN } from '@/methods/rules/access';
import { access, community } from '@/data';
import initBuildAptUserChoose from './init-build-apt-user-choose';

const initForm = (
    opera: 'add' | 'edit',
    type: 'pin' | 'rfCard'
) => {
    const date = new Date();
    // form相关操作
    const formRef: Ref<any> = ref();
    const formData = ref<AddFormDataType>({
        Build: '',
        Room: '',
        AccountID: '',
        ID: '',
        Key: '',
        SchedulerType: type === 'pin' ? '0' : '1',
        DateFlag: [],
        StartTime: type === 'pin' ? '' : new Date(date.getFullYear(), date.getMonth(), date.getDay(), 0, 0, 0),
        StopTime: type === 'pin' ? '' : new Date(date.getFullYear(), date.getMonth(), date.getDay(), 23, 59, 59),
        BeginTime: '',
        EndTime: '',
        MAC: [],
        Devices: [],
        Type: type === 'pin' ? 0 : 1
    });

    if (opera === 'edit') {
        const data: AddFormDataType = JSON.parse(localStorage.getItem('propertyKeyDetail') as string);
        formData.value = {
            ...data,
            Key: data.Code!,
            StartTime: new Date(`2000-01-01 ${data.StartTime}`),
            StopTime: new Date(`2000-01-01 ${data.StopTime}`),
            BeginTime: data.BeginTime === '0000-00-00 00:00:00' ? '' : data.BeginTime,
            EndTime: data.EndTime === '0000-00-00 00:00:00' ? '' : data.EndTime,
            DateFlag: data.DateFlag.length > 0 ? (data.DateFlag as string).split(';') : []
        };
    }

    return {
        formRef,
        formData
    };
};

const initOptions = () => {
    const repeatOptions = [{
        label: WordList.ProperAllTextNever,
        value: '0'
    }, {
        label: WordList.ProperAllTextDaily,
        value: '1'
    }, {
        label: WordList.ProperAllTextWeekly,
        value: '2'
    }];

    const {
        buildOptions,
        aptOptions,
        userOptions,
        setRoomOption,
        setUserOptions
    } = initBuildAptUserChoose();

    return {
        repeatOptions,
        buildOptions,
        aptOptions,
        userOptions,
        setRoomOption,
        setUserOptions
    };
};

const headers: ListHeaderItem[] = [{
    name: 'UnitName',
    label: WordList.DeviceDetailDetailUnitName
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}, {
    name: 'Type',
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
    type: 'customize'
}, {
    name: 'Relay',
    label: WordList.ProperAllTextRelay,
    type: 'customize',
    headerType: 'customize'
}];

const initList = (
    roomID: string
) => {
    const requestData: RequestData = reactive({
        url: 'v3/web/community/device/getAssociationForPm',
        param: {
            RoomID: roomID
        }
    });

    const update = ref(0);

    return {
        headers,
        requestData,
        update
    };
};

const getFormRules = (
    schedulerType: string,
    type: 'pin' | 'rfCard',
    operation: 'add' | 'edit'
) => {
    const baseRules = {
        Build: [{
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.DeviceDetailDetailUnitName),
            trigger: 'blur'
        }],
        Room: [{
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.RDeviceSearchLabelRoomName),
            trigger: 'blur'
        }],
        AccountID: [{
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextResident),
            trigger: 'blur'
        }],
        Name: [{
            required: true,
            message: WordList.RuleNameEmpty,
            trigger: 'blur'
        }],
        Key: [{
            required: true,
            message: WordList.RuleKeyEmpty,
            trigger: 'blur'
        }, {
            validator: type === 'pin' ? checkOldCommunityDeliveryPIN(community.IsEncryptPin.value === '1', operation) : checkCard,
            trigger: 'blur'
        }]
    };
    const neverRules = {
        BeginTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlBeginTime),
            trigger: 'blur'
        }],
        EndTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }]
    };
    const dailyRules = {
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }]
    };
    const weeklyRules = {
        StartTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TmpKeyManageListTanleBeginTime),
            trigger: 'blur'
        }],
        StopTime: [{
            type: 'date',
            required: true,
            message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelTmpKeyInHtmlEndTime),
            trigger: 'blur'
        }],
        DateFlag: [{
            required: true,
            message: WordList.ProperAllTextDayRequired,
            trigger: 'blur'
        }]
    };
    if (schedulerType === access.never) {
        return {
            ...baseRules,
            ...neverRules
        };
    }
    if (schedulerType === access.daily) {
        return {
            ...baseRules,
            ...dailyRules
        };
    }
    // weekly
    return {
        ...baseRules,
        ...weeklyRules
    };
};
export default null;
export {
    initForm,
    initOptions,
    initList,
    getFormRules
};
