
import {
    computed, defineComponent, PropType, ref, watch
} from 'vue';
import router, { propertyBaseRouter, propertyOldCommunitySubRouter } from '@/router';
import PropertyBreadCrumb from '@/components/view/pm/bread-crumb';
import PinEncryptInput from '@/components/common/pin-encrypt-input/index.vue';
import {
    initForm, initOptions, initList, getFormRules
} from '@/views/pm/old-community/resident-auth/module/set';
import { access } from '@/data';
import { list } from '@/components/common/list';
import initCheck from '@/methods/relay-choose';
import Notice from '@/util/notice.base';
import { residentAuthApi } from '@/api';
import { format } from '@/methods/date';
import DeviceIcon from '@/components/view/common/device-icon/index.vue';

export default defineComponent({
    components: {
        PropertyBreadCrumb,
        PinEncryptInput,
        list,
        DeviceIcon
    },
    props: {
        type: {
            type: String as PropType<'pin' | 'rfCard'>,
            default: 'pin'
        },
        opera: {
            type: String as PropType<'add' | 'edit'>,
            default: 'add'
        }
    },
    setup(props) {
        const breadHeaderLabel = {
            add: {
                pin: WordList.DeliveryAddPKey,
                rfCard: WordList.DeliveryAddRCard
            },
            edit: {
                pin: WordList.ProperAllTextEditPin,
                rfCard: WordList.DeliveryEditRCard
            }
        };
        const breadHeaders = [{
            label: WordList.ProperAllTextResidentAuth,
            path: `/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResidentAuth}?type=${props.type}`
        }, {
            label: breadHeaderLabel[props.opera][props.type]
        }];

        const {
            formRef,
            formData
        } = initForm(props.opera, props.type);
        // 监听切换模式，加载不同rules
        const rules = ref(getFormRules(formData.value.SchedulerType, props.type, props.opera));
        watch(computed(() => formData.value.SchedulerType), () => {
            rules.value = getFormRules(formData.value.SchedulerType, props.type, props.opera);
        });

        const {
            repeatOptions,
            buildOptions,
            aptOptions,
            userOptions,
            setRoomOption,
            setUserOptions
        } = initOptions();

        // 监听选择楼栋，动态改变apt
        watch(() => formData.value.Build, (buildId) => {
            if (props.opera === 'add') {
                formData.value.Room = '';
            }
            setRoomOption(String(buildId));
        }, {
            immediate: true
        });

        // list数据
        const {
            headers,
            requestData,
            update
        } = initList(formData.value.Room);

        // 监听选择房间，动态改变user
        watch(() => formData.value.Room, (roomId) => {
            if (props.opera === 'add') {
                formData.value.AccountID = '';
            }
            if (formData.value.Room !== '') {
                setUserOptions(String(roomId));
                requestData.param.RoomID = roomId;
                update.value += 1;
            }
        }, {
            immediate: true
        });

        // relay
        const {
            allCheck, checkArray, dealList, choose, getCommit, changeAllCheck, setCheck
        } = initCheck();
        if (props.opera === 'edit') {
            setCheck(formData.value.Devices, update);
        }

        // 提交
        const submit = () => {
            const devices = getCommit();
            if (devices.length === 0) {
                Notice.messageBox(
                    'alert',
                    WordList.RuleMacChoose,
                    WordList.PersonuserInfo,
                    'info'
                )(() => false);
                return false;
            }
            const url = {
                pin: {
                    add: residentAuthApi.addResidentPin,
                    edit: residentAuthApi.editResidentPin
                },
                rfCard: {
                    add: residentAuthApi.addResidentRfCard,
                    edit: residentAuthApi.editResidentRfCard
                }
            };
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    const param = { ...formData.value };
                    if (param.SchedulerType === access.weekly && typeof param.DateFlag !== 'string') {
                        param.DateFlag = param.DateFlag.join(';');
                    }
                    param.BeginTime = format(param.BeginTime, 'YYYY-MM-DD HH:mm:ss');
                    param.EndTime = format(param.EndTime, 'YYYY-MM-DD HH:mm:ss');
                    param.StartTime = format(param.StartTime, 'HH:mm:ss');
                    param.StopTime = format(param.StopTime, 'HH:mm:ss');
                    url[props.type][props.opera]({ ...param, MAC: devices }, () => {
                        if (localStorage.getItem('modifyKey') === '1') {
                            localStorage.removeItem('modifyKey');
                            localStorage.removeItem('propertyKeyDetail');
                            router.back();
                        }
                        router.push(`/${propertyBaseRouter}/${propertyOldCommunitySubRouter.oldResidentAuth}?type=${props.type}`);
                    });
                }
            });
            return true;
        };

        return {
            breadHeaders,
            formRef,
            formData,
            buildOptions,
            aptOptions,
            repeatOptions,
            access,
            userOptions,
            headers,
            submit,
            requestData,
            dealList,
            changeAllCheck,
            checkArray,
            update,
            choose,
            allCheck,
            rules
        };
    }
});
